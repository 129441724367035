import { graphql, PageProps } from "gatsby";
import React from "react";
import Image from "../components/Image";
import Page from "../components/Page";
import { PageTitle } from "../components/PageTitle";
import { Paragraph } from "../components/Paragraph";
import SEO from "../components/SEO";
import { PrismicContact } from "../prismic-types";
import theme from "../theme";
import { innerHtmlFromPrismicRaw } from "../util";
import { StyledBioSection } from "./biography";

type QueryData = { prismicContact: PrismicContact };

export const query = graphql`
  {
    prismicContact {
      data {
        page_title {
          text
        }
        contact_text {
          raw
        }
        image_title {
          text
        }
        image_credit_link
        image_credit_name
        contact_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 90) {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
        }
      }
    }
  }
`;

const ContactPage: React.FC<PageProps<QueryData>> = ({ data }) => {
  const contactTextParagraphs: [] = data.prismicContact.data?.contact_text?.raw;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const imageData = data.prismicContact.data?.contact_image?.localFile
    ?.childImageSharp?.fluid!;
  const imageTitle = data.prismicContact.data?.image_title?.text ?? "";
  const imageCreditTitle = data.prismicContact?.data?.image_credit_name ?? "";
  const imageCreditLink = data.prismicContact?.data?.image_credit_link ?? "";
  return (
    <Page>
      <SEO title="Contact" />
      <PageTitle>{data.prismicContact.data?.page_title?.text ?? ""}</PageTitle>
      <StyledBioSection>
        {contactTextParagraphs.map((bp, idx) => {
          return (
            <Paragraph
              key={`contact-para-${idx}`}
              innerHTML={innerHtmlFromPrismicRaw(bp)}
              dropCap={false}
            />
          );
        })}
      </StyledBioSection>
      <div style={{ marginBottom: theme.space[10] }} />
      <Image
        title={imageTitle}
        credit={{ title: imageCreditTitle, link: imageCreditLink }}
        data={imageData}
      />
    </Page>
  );
};

export default ContactPage;
